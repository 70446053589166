<template>
  <div
    class="select"
    :class="{ 'select--expanded': visible }"
    v-click-outside="onClickOutside"
  >
    <div
      class="select-item"
      @click.stop="onClick"
    >
      <div
        class="screen-label"
        v-if="$slots.default"
      >
        <slot></slot>
      </div>
      <div class="select-card">
        <div class="screen-label">{{ displayLabel }}</div>
        <img
          src="@/view/jxyh/assets/icons/icon_pull_down.png"
          class="icon-PullDown"
          ref="rowIcon"
        />
      </div>
    </div>
    <ul class="list scrollbar">
      <li
        class="list-item"
        v-for="(item, index) in data"
        :key="'overall' + index"
        :class="{ 'list-item--active': currentValue == item.id }"
        @click.stop="onSelect(item)"
      >
        <span>{{ item.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside"
export default {
  props: {
    label: {
      type: String,
      default: "所属期间",
    },
    data: {
      type: Array,
      default() {
        return []
      },
    },
    value: String | Number,
  },
  watch: {
    value(val) {
      if (this.currentValue !== val) this.currentValue = val
    },
  },
  computed: {
    displayLabel() {
      let index = this.data.findIndex((it) => it.id === this.currentValue)
      if (this.data && this.data.length) {
        if (index == -1) {
          this.currentValue = this.data[0].id
        }
        return index == -1 ? `${this.data[0].label}` : `${this.data[index].label}`
      } else {
        return index == -1 ? "请选择" : `${this.data[index].label}`
      }
    },
  },
  data() {
    return {
      visible: false,
      currentValue: 1,
      deg: 0,
    }
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.bus.$on("selectOperateEvent", (type) => {
  //       this.currentValue = type
  //     })
  //     this.bus.$on("selectProcureEvent", (type) => {
  //       this.currentValue = type
  //     })
  //   })
  // },
  methods: {
    onClick() {
      if (this.data && this.data.length) {
        this.visible = !this.visible
        this.rotate()
      }
    },
    rotate() {
      this.deg = this.deg + 180
      this.$refs.rowIcon.style.transform = `rotate(${this.deg}deg)`
    },
    onSelect(item) {
      this.currentValue = item.id
      this.$emit("input", this.currentValue)
      this.visible = false
      this.rotate()
      this.$emit("selectType", item.id)
    },
    onClickOutside() {
      if (this.visible) {
        this.rotate()
        this.visible = false
      }
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>
<style lang="less" scoped>
.select {
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  &-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: 10px;
    padding: 7px 10px;
    width: 120px;
  }
  .select-item {
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #66bbf9;
    .icon-PullDown {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
  }
  .list {
    z-index: 1005;
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    background: #1e5087;
    border: 1px solid #187bcd;
    visibility: hidden;
    box-sizing: border-box;
    opacity: 0;
    transition: 0.3s;
    padding: 6px 0;
    transform: translateY(35px);
    max-height: 187px;
    overflow: auto;
    width: fit-content;
    min-width: 100%;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: transparent;

      &:hover {
      }
      &.list-item--active {
        color: #fff;
        background: #143a64;
      }
    }
  }
  &.select--expanded {
    .list {
      opacity: 1;
      visibility: visible;
      transform: translateY(0%);
    }
    .icon {
      transform: rotate(180deg);
    }
  }
}
.screen-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
